import axios from 'axios'

axios.interceptors.response.use(
  function (response) {
    // console.log(response);
    //对响应数据做点什么
    return response
  },
  function (error) {
    if (error.response.status === 200) {
      return Promise.reject(error)
    } else {
      return Promise.reject(error.response.data.msg)
    }
  }
)
// 基本配置
//测试打包
const api = process.env.VUE_APP_API_BASE_URL
console.log('api===', api)
export default {
  getAssetUsufructByMonth(yearMonth, projectId) {
    return axios.get(
      api +
        `finance/bill/getAssetUsufructByMonth?yearMonth=${yearMonth}&projectId=${projectId}`
    )
  },
  getAssetUsufructByYear(year, projectId) {
    return axios.get(
      api +
        `finance/bill/getAssetUsufructByYear?yearMonth=${year}&projectId=${projectId}`
    )
  },
  getyanz() {
    return axios.get(api + 'auth/sign/status')
  },
  //icp
  getprojectInfoall() {
    return axios.get(api + 'public/projectInfo')
  },
  //阿里云上传
  getsts() {
    return axios.get(api + 'public/sts')
  },
  getstsp() {
    return axios.get(api + 'public/stsp')
  },
  //本地上传
  upload(file) {
    return axios.post(api + 'public/upload', { file: file })
  },
  download(name, type, cid) {
    return axios.get(api + `public/download/${name}?type=${type}&cid=${cid}`)
  },

  //修改当前项目
  getprojectlist() {
    return axios.get(api + 'property/project/list')
  },
  postthisproject(projectId) {
    return axios.post(api + `auth/sign/project/${projectId}`)
  },
  // 安全中心
  contmobile(mobile) {
    //手机验证码
    return axios.post(api + 'auth/security/mobile/code', { mobile })
  },
  contmail(mail) {
    //邮箱验证码
    return axios.post(api + 'auth/security/mail/code', { mail })
  },
  putmobile(form) {
    return axios.put(api + 'auth/security/mobile', form)
  },
  // 忘记密码
  forgotmobile(form) {
    //手机验证码
    return axios.post(api + 'auth/forgot/mobile/code', form)
  },
  forgotmail(form) {
    //邮箱验证码
    return axios.post(api + 'auth/forgot/mail/code', form)
  },
  forgotrest(form) {
    //改密码
    return axios.post(api + 'auth/forgot/reset', form)
  },
  securityreset(form) {
    //重置初始密码
    return axios.put(api + 'auth/security/reset', form)
  },
  // 个人中心
  getuser() {
    return axios.get(api + 'auth/personal/user')
  },
  putuser(form) {
    return axios.put(api + 'auth/personal/user', form)
  },
  //个人中心 修改密码
  putauthpassword(form) {
    return axios.put(api + 'auth/security/password', form)
  },
  //首次登录，修改密码验证
  getsecuritycheck(password) {
    return axios.post(api + 'auth/security/check', { password })
  },
  // 登录
  getlogin(user) {
    return axios.post(api + 'auth/sign/in', user)
  },
  getmobileCode(code, mobile) {
    return axios.get(api + `auth/sign/mobileCode/${code}/${mobile}`)
  },
  getlogout() {
    return axios.get(api + 'auth/sign/out')
  },
  getweixin() {
    return axios.get(api + 'auth/sign/wxCodeSign')
  },
  getwxInfo() {
    return axios.get(api + 'auth/sign/wxSignInfo')
  },
  //微信绑定
  getweixinlink() {
    return axios.get(api + 'auth/sign/wxCodeLink')
  },
  getwxInfolink() {
    return axios.get(api + 'auth/sign/wxLinkInfo')
  },
  getuserthing() {
    //获取路由
    return axios.get(api + 'auth/sign/user')
  },
  getdistrict() {
    return axios.get(api + 'public/district')
  },
  getdistrict2() {
    return axios.get(api + 'public/district/2')
  },
  //welcome
  getwelcomebasic() {
    return axios.get(api + 'welcome/basic')
  },
  getwelcomeunPay() {
    return axios.get(api + 'welcome/unPay')
  },
  getwelcomerentRate() {
    return axios.get(api + 'welcome/rentRate')
  },
  getwelcomerentRateByProjectId(projectId) {
    return axios.get(api + 'welcome/rentRateByProjectId/' + projectId)
  },
  getwelcomebusiness() {
    return axios.get(api + 'welcome/business')
  },
  getwelcomemessage() {
    return axios.get(api + 'welcome/message')
  },
  getwelcomeoperatest() {
    return axios.get(api + 'welcome/operate')
  },
  //报表
  getdashboardday() {
    return axios.get(api + 'dashboard/day')
  },
  getdashboardtotalFee() {
    return axios.get(api + 'dashboard/totalFee')
  },
  getdashboardpayedFee() {
    return axios.get(api + 'dashboard/payedFee')
  },
  getdashboardunPayedFee() {
    return axios.get(api + 'dashboard/unPayedFee')
  },
  getdashboardmap() {
    return axios.get(api + 'dashboard/map')
  },
  getdashboardlastRent() {
    return axios.get(api + 'dashboard/lastRent')
  },
  getdashboardbusinessTypes() {
    return axios.get(api + 'dashboard/businessTypes')
  },
  getdashboardtalks() {
    return axios.get(api + 'dashboard/talks')
  },
  getweathercity() {
    return axios.get(api + 'dashboard/weather')
  },
  //模板
  posttemplate(form) {
    return axios.post(api + 'config/template', form)
  },
  gettemplateid(id) {
    return axios.get(api + `config/template/${id}`)
  },
  gettemplate(params) {
    return axios.post(api + 'config/template/search', {
      params,
      sorter: {},
    })
  },
  deletetemplate(id) {
    return axios.delete(api + 'config/template/delete', { data: id })
  },
  statetemplate(id, state) {
    return axios.patch(api + 'config/template/state', { id, state })
  },
  puttemplate(form) {
    return axios.put(api + 'config/template/update', form)
  },
  gettemplatelist(type) {
    return axios.get(api + `config/template/list/${type}`)
  },

  // 公司
  getEnterPrise(params) {
    return axios.post(api + 'config/enterprise/search', {
      params,
      sorter: {},
    })
  },
  Addenterprise(form) {
    return axios.post(api + 'config/enterprise', form)
  },
  putenterprise(form) {
    return axios.put(api + 'config/enterprise', form)
  },
  getenterpriseid(id) {
    return axios.get(api + `config/enterprise/${id}`)
  },
  getenterpriselist() {
    return axios.get(api + `config/enterprise/list`)
  },
  deleteerprise(key) {
    return axios.delete(api + 'config/enterprise', { data: key })
  },
  stateprise(id, state) {
    return axios.patch(api + 'config/enterprise/state', { id, state })
  },
  deleteuserarea(architectureId, userId) {
    return axios.delete(
      api + `config/architecture/user/remove/${architectureId}/${userId}`
    )
  },
  // 架构
  getArchitectlist() {
    return axios.get(api + 'config/architecture/list')
  },
  getArchitecture(current, pageSize) {
    return axios.post(api + 'config/architecture/search', {
      params: { current, pageSize },
      sorter: {},
    })
  },
  getArchiteid(id) {
    return axios.get(api + `config/architecture/${id}`)
  },
  putArchitecture(form) {
    return axios.put(api + `config/architecture`, form)
  },
  AddArchitecture(form) {
    return axios.post(api + 'config/architecture', form)
  },
  stateArchitecture(id, state) {
    return axios.patch(api + 'config/architecture/state', { id, state })
  },
  deleteArchitecture(key) {
    return axios.delete(api + `config/architecture/${key}`)
  },
  // 角色管理
  getPosition(params) {
    return axios.post(api + 'config/position/search', {
      params,
      sorter: {},
    })
  },
  getPositionId(id) {
    return axios.get(api + `config/position/${id}`)
  },
  getpositionlist() {
    return axios.get(api + 'config/position')
  },
  putPosition(form) {
    return axios.put(api + `config/position`, form)
  },
  AddPosition(form) {
    return axios.post(api + 'config/position', form)
  },
  deletePosition(key) {
    return axios.delete(api + 'config/position', { data: key })
  },
  statePosition(id, state) {
    return axios.patch(api + 'config/position/state', { id, state })
  },
  //系统配置
  getdayLimit() {
    return axios.get(api + `config/params/dayLimit`)
  },
  postconfigparams(form) {
    return axios.post(api + `config/params`, form)
  },
  //角色权限管理
  gainPositionmodule(id) {
    //获取已绑定模块树
    return axios.get(api + `config/position/bind/modules/${id}`)
  },
  gainPositionmoduleId(id, key) {
    //更新模块
    return axios.put(api + `config/position/bind/modules/${id}`, key)
  },
  getpositionuser(current, pageSize, positionId) {
    //获取所有员工
    return axios.post(api + `config/position/bind/user/search/position`, {
      params: {
        current,
        pageSize,
        position_id: { value: positionId, op: '=' },
      },
      sorter: {},
    })
  },
  getInvitpositionuser(positionId, userId) {
    //添加员工
    return axios.get(
      api + `config/position/bind/user/invite/${positionId}/${userId}`
    )
  },
  delpositionuser(positionId, userId) {
    //删除yuango
    return axios.delete(
      api + `config/position/bind/user/remove/${positionId}/${userId}`
    )
  },
  // 员工
  getarchitectureUser(current, pageSize, architectureId) {
    return axios.post(api + 'config/architecture/user/search/architecture', {
      params: {
        current,
        pageSize,
        architecture_id: { value: architectureId },
      },
      sorter: {},
    })
  },
  deleteuser(key) {
    return axios.delete(api + 'config/user', { data: key })
  },
  getUser(params) {
    return axios.post(api + 'config/user/search', {
      params,
      sorter: {},
    })
  },
  getUsernId(id) {
    return axios.get(api + `config/user/${id}`)
  },
  putUser(form) {
    return axios.put(api + `config/user`, form)
  },
  AddUser(form) {
    return axios.post(api + 'config/user', form)
  },
  getuserlist() {
    return axios.get(api + 'config/user/list')
  },
  getuserlistCurrent() {
    return axios.get(api + 'config/user/listCurrent')
  },
  getexcel() {
    return axios.get(api + 'config//user/excel/export')
  },
  importexcel(file) {
    return axios.post(api + 'config/architecture/user/excel/import', file)
  },
  stateUser(id, state) {
    return axios.patch(api + 'config/user/state', {
      id,
      state,
    })
  },
  // 模块管理
  getInvitUser(architectureId, userId) {
    return axios.get(
      api + `config/architecture/user/invite/${architectureId}/${userId}`
    )
  },
  getModule(params) {
    return axios.post(api + 'config/module/search', {
      params,
      sorter: {},
    })
  },
  getModuleId(id) {
    return axios.get(api + `config/module/${id}`)
  },
  putModule(form) {
    return axios.put(api + `config/module`, form)
  },
  AddModule(form) {
    return axios.post(api + 'config/module', form)
  },
  deleteModule(key) {
    return axios.delete(api + 'config/module', { data: key })
  },
  stateModule(id, state) {
    return axios.patch(api + 'config/module/state', { id, state })
  },
  //流程管理
  getprocess(params) {
    return axios.post(api + 'config/process/search', {
      params,
      sorter: {},
    })
  },
  getprocessId(id) {
    return axios.get(api + `config/process/${id}`)
  },
  putprocess(id, form) {
    return axios.put(api + `config/process/${id}`, form)
  },
  //流程绑定
  getprocessMap(current, pageSize) {
    return axios.post(api + 'config/processMapping/search', {
      params: { current, pageSize },
      sorter: {},
    })
  },
  //匹配流程
  getprocessresetId(id) {
    return axios.get(api + `config/process/reset/${id}`)
  },
  putprocessMap(form) {
    return axios.put(api + 'config/processMapping', form)
  },
  // 企业资产数据管理
  getProject(params) {
    return axios.post(api + 'property/project/search', {
      params,
      sorter: {},
    })
  },
  //查询全部
  getProject1(params) {
    return axios.post(api + 'property/project/search1', {
      params,
      sorter: {},
    })
  },
  getProjectId(id) {
    return axios.get(api + `property/project/${id}`)
  },
  putProject(form) {
    return axios.put(api + `property/project`, form)
  },
  AddProject(form) {
    return axios.post(api + 'property/project', form)
  },
  deleteProject(key) {
    return axios.delete(api + 'property/project', { data: key })
  },
  getProjecttype() {
    return axios.get(api + 'property/project/type')
  },
  stateProject(id, state) {
    return axios.patch(api + 'property/project/state', { id, state })
  },
  //品牌库
  getcloud(params) {
    return axios.post(api + 'brands/cloud/search', {
      params,
      sorter: { linkman_level: 'desc' },
    })
  },
  getcloudId(id) {
    return axios.get(api + `brands/cloud/${id}`)
  },
  deletecloud(key) {
    return axios.post(api + 'brands/cloud/copy', key)
  },
  getcloudcheck(id) {
    return axios.get(api + `brands/cloud/check/${id}`)
  },
  getcloudcontact(id) {
    return axios.get(api + `brands/cloud/contact/${id}`)
  },
  getcloudwatch(id, check) {
    return axios.get(api + `brands/cloud/watch/${id}/${check}`)
  },
  getcloudusage() {
    return axios.get(api + `brands/cloud/usage`)
  },
  //本地品牌库
  getNative(params) {
    return axios.post(api + 'brands/native/search', {
      params,
      sorter: {},
    })
  },
  getNativeId(id) {
    return axios.get(api + `brands/native/${id}`)
  },
  getNativelist(name) {
    return axios.get(api + `brands/native/list?name=${name}`)
  },
  getNativelistID(id) {
    return axios.get(api + `brands/native/${id}`)
  },
  putNative(form) {
    return axios.put(api + `brands/native`, form)
  },
  AddNative(form) {
    return axios.post(api + 'brands/native', form)
  },
  deleteNative(key) {
    return axios.delete(api + 'brands/native', { data: key })
  },
  // 楼栋管理
  getBuilding(current, pageSize) {
    return axios.post(api + 'property/building/search', {
      params: { current, pageSize },
      sorter: {},
    })
  },
  getBuildingId(id) {
    return axios.get(api + `property/building/${id}`)
  },
  putBuilding(form) {
    return axios.put(api + `property/building`, form)
  },
  AddBuilding(form) {
    return axios.post(api + 'property/building', form)
  },
  deleteBuilding(buildingId) {
    return axios.delete(api + `property/building/${buildingId}`)
  },
  // 楼栋管理
  getFloor(current, pageSize) {
    return axios.post(api + 'property/floor/search', {
      params: { current, pageSize },
      sorter: {},
    })
  },
  getFloorId(id) {
    return axios.get(api + `property/floor/${id}`)
  },
  putFloor(form) {
    return axios.put(api + `property/floor`, form)
  },
  AddFloor(form) {
    return axios.post(api + 'property/floor', form)
  },
  deleteFloor(floorId) {
    return axios.delete(api + `property/floor/${floorId}`)
  },
  // 铺位管理
  getStore(params) {
    return axios.post(api + 'property/store/search', {
      params,
      sorter: {},
    })
  },
  getStoreId(id) {
    return axios.get(api + `property/store/${id}`)
  },
  putStore(form) {
    return axios.put(api + `property/store`, form)
  },
  AddStore(form) {
    return axios.post(api + 'property/store', form)
  },
  deleteStore(key) {
    return axios.delete(api + 'property/store', { data: key })
  },
  exportstore() {
    return axios.get(api + 'property/store/excel/export')
  },
  Statestore(id, state) {
    return axios.patch(api + 'property/store/state', { id, state })
  },
  getStoreinfo(id) {
    return axios.get(api + `property/store/info/${id}`)
  },
  // 铺位调整
  getstoreAdjust(params) {
    return axios.post(api + 'property/storeAdjust/search', {
      params,
      sorter: {},
    })
  },
  getstoreAdjustId(id) {
    return axios.get(api + `property/storeAdjust/${id}`)
  },
  StoreAdjust(form) {
    return axios.post(api + 'property/storeAdjust', form)
  },
  putStoreAdjust(form) {
    return axios.put(api + 'property/storeAdjust', form)
  },
  deleteAdjust(key) {
    return axios.delete(api + 'property/storeAdjust', { data: key })
  },
  StatestoreAdjust(id, state) {
    return axios.patch(api + 'property/storeAdjust/state', { id, state })
  },
  project() {
    //项目ID
    return axios.get(api + 'property/project/list')
  },
  buildingid(projectId) {
    //楼栋ID
    return axios.get(api + `property/building/list/${projectId}`)
  },
  floorid(buildingId) {
    //楼层ID
    return axios.get(api + `property/floor/list/${buildingId}`)
  },
  storeid(floorId) {
    //铺位ID
    return axios.get(api + `property/store/list/${floorId}`)
  },
  storetype(floorId, type) {
    //铺位ID
    return axios.get(api + `property/store/list/${floorId}/${type}`)
  },
  type() {
    //type
    return axios.get(api + 'public/propertyType/list')
  },
  StoreAdjustsubmit(form) {
    return axios.patch(api + 'property/storeAdjust/submit', form)
  },
  StoreAdjustcancel(form) {
    return axios.patch(api + 'property/storeAdjust/cancel', form)
  },
  StoreAdjustreset(form) {
    return axios.patch(api + 'property/storeAdjust/reset', form)
  },
  //铺位审核
  getstoreAuditrecord(current, pageSize) {
    return axios.post(api + 'property/storeAdjustAudit/record', {
      params: { current, pageSize },
      sorter: {},
      filter: {},
    })
  },
  getstoreAuditsearch(current, pageSize) {
    return axios.post(api + 'property/storeAdjustAudit/search', {
      params: { current, pageSize },
      sorter: {},
    })
  },
  storeAdjustAudit(form) {
    return axios.post(api + 'property/storeAdjustAudit', form)
  },
  //多经管理
  getdj(params) {
    return axios.post(api + 'property/dj/search', {
      params,
      sorter: {},
    })
  },
  getdjId(id) {
    return axios.get(api + `property/dj/${id}`)
  },
  putdj(form) {
    return axios.put(api + `property/dj`, form)
  },
  Adddj(form) {
    return axios.post(api + 'property/dj', form)
  },
  deletedj(key) {
    return axios.delete(api + 'property/dj', { data: key })
  },
  exportdj() {
    return axios.get(api + 'property/dj/excel/export')
  },
  Statedj(id, state) {
    return axios.patch(api + 'property/dj/state', { id, state })
  },
  //广告管理
  getad(params) {
    return axios.post(api + 'property/ad/search', {
      params,
      sorter: {},
    })
  },
  getadId(id) {
    return axios.get(api + `property/ad/${id}`)
  },
  putad(form) {
    return axios.put(api + `property/ad`, form)
  },
  Addad(form) {
    return axios.post(api + 'property/ad', form)
  },
  deletead(key) {
    return axios.delete(api + 'property/ad', { data: key })
  },
  exportad() {
    return axios.get(api + 'property/ad/excel/export')
  },
  Statead(id, state) {
    return axios.patch(api + 'property/dj/state', { id, state })
  },
  //招商规划
  //总览
  getpropertyst() {
    return axios.get(api + `/plan/plan/brand/1461621750613229572/0`)
  },
  getpropertyId(id) {
    return axios.get(api + `property/store/${id}`)
  },
  //方案管理
  getPlan(params) {
    return axios.post(api + 'plan/plan/search', {
      params,
      sorter: {},
    })
  },
  getPlanId(id) {
    return axios.get(api + `plan/plan/${id}`)
  },
  putPlan(form) {
    return axios.put(api + `plan/plan`, form)
  },
  AddPlan(form) {
    return axios.post(api + 'plan/plan', form)
  },
  deletePlan(key) {
    return axios.delete(api + 'plan/plan', { data: key })
  },
  StatePlan(id, state) {
    return axios.patch(api + 'plan/plan/state', { id, state })
  },
  //方案规划
  getPlandetail(current, pageSize) {
    return axios.post(api + 'plan/detail/search', {
      params: {
        current,
        pageSize,
        plan_id: { value: '1428617765715546114', op: '=' },
      },
      sorter: {},
    })
  },
  putDetail(form) {
    return axios.put(api + `plan/plan/detail`, form)
  },
  getplanstoredetail(planId, storeId) {
    return axios.get(api + `plan/plan/detail/${planId}/${storeId}`)
  },
  //下拉框
  BusinessTypelist() {
    return axios.get(api + `public/businessType/list`)
  },
  brand() {
    return axios.get(api + `plan/detail/brand/list`)
  },
  //品牌落位
  getplanlist() {
    return axios.get(api + `plan/plan/list`)
  },
  getpropertybuildlist() {
    return axios.get(api + `property/building/list`)
  },
  getpropertyfloorlist(buildingId) {
    return axios.get(api + `property/floor/list/${buildingId}`)
  },
  getpropertystorelist(floorId) {
    return axios.get(api + `plan/plan/brand/${floorId}/0`)
  },
  getbranddetail(planId, storeId) {
    return axios.get(api + `plan/plan/brand/detail/${planId}/${storeId}`)
  },
  postplanbrand(form) {
    return axios.put(api + `plan/plan/brand/detail`, form)
  },

  //招商实施
  getClient(params, sorter) {
    return axios.post(api + 'business/client/search', {
      params,
      sorter,
    })
  },
  getClientId(id) {
    return axios.get(api + `business/client/${id}`)
  },
  putClient(form) {
    return axios.put(api + `business/client`, form)
  },
  AddClient(form) {
    return axios.post(api + 'business/client', form)
  },
  deleteClient(key) {
    return axios.delete(api + 'business/client', { data: key })
  },
  getClientInfo(keyword) {
    return axios.get(api + `business/client/info`, { params: { keyword } })
  },
  Stateclient(id, state) {
    return axios.patch(api + 'business/client/state', { id, state })
  },
  getClientcontact(id) {
    return axios.get(api + `business/client/contacts/${id}`)
  },
  getClientlist() {
    return axios.get(api + `business/client/list/sign`)
  },
  //洽谈管理
  getTalk(params) {
    return axios.post(api + 'business/talk/search', {
      params,
      sorter: {},
    })
  },
  getTalkId(id) {
    return axios.get(api + `business/talk/${id}`)
  },
  getTalkhistory(talkId) {
    return axios.get(api + `business/talk/history/${talkId}`)
  },
  putTalk(form) {
    return axios.put(api + `business/talk`, form)
  },
  putfollow(form) {
    return axios.put(api + `business/talk/follow`, form)
  },
  AddTalk(form) {
    return axios.post(api + 'business/talk', form)
  },
  StateTalk(id, state) {
    return axios.patch(api + 'business/talk/state', { id, state })
  },
  deleteTalk(key) {
    return axios.delete(api + 'business/talk', { data: key })
  },
  Talkfile(id, files) {
    return axios.patch(api + 'business/talk/resource', { id, files })
  },
  gettalklist() {
    //获取洽谈列表
    return axios.get(api + 'business/talk/list')
  },

  //意向管理
  getIntention(params) {
    return axios.post(api + 'business/intention/search', {
      params,
      sorter: {},
    })
  },
  getIntentionId(id) {
    return axios.get(api + `business/intention/${id}`)
  },
  auditIntention(id) {
    //审核记录
    return axios.get(api + `business/intention/audits/${id}`)
  },
  getintendiscard(id) {
    //作废
    return axios.get(api + `business/intention/discard/${id}`)
  },
  putIntention(form) {
    return axios.put(api + `business/intention`, form)
  },
  AddIntention(form) {
    return axios.post(api + 'business/intention', form)
  },
  deleteIntention(key) {
    return axios.delete(api + 'business/intention', { data: key })
  },
  getintentionstate(id, state) {
    return axios.patch(api + 'business/intention/state', { id, state })
  },
  getIntentionlist() {
    //获取列表
    return axios.get(api + 'business/intention/list')
  },
  changebelongto(id, belongTo) {
    //更改意向所属
    return axios.patch(api + 'business/intention/belong', { id, belongTo })
  },
  intentionfile(id, files) {
    return axios.patch(api + 'business/intention/resource', { id, files })
  },
  intentionagreement(id, agreement) {
    return axios.patch(api + 'business/intention/agreement', {
      id,
      agreement,
    })
  },
  putIntentPayment(form) {
    return axios.put(api + `business/intention/payment`, form)
  },
  AddIntentPayment(form) {
    return axios.post(api + 'business/intention/payment', form)
  },
  getIntentPaymentId(id) {
    return axios.get(api + `business/intention/payment/${id}`)
  },
  //提交
  intentionsubmit(form) {
    return axios.patch(api + 'business/intention/submit', form)
  },
  intentioncancel(form) {
    return axios.patch(api + 'business/intention/cancel', form)
  },
  intentionreset(form) {
    return axios.patch(api + 'business/intention/reset', form)
  },
  // getpaymentlist(){
  //     return axios.get('/api/sccn/finance/paymentInfo/list')
  // },
  //意向审核
  getintentionAuditsearch(current, pageSize) {
    return axios.post(api + 'business/intentionAudit/search', {
      params: { current, pageSize },
      sorter: {},
    })
  },
  getintentionAuditrecord(current, pageSize) {
    return axios.post(api + 'business/intentionAudit/record', {
      params: { current, pageSize },
      sorter: {},
    })
  },
  postintentionAudit(form) {
    return axios.post(api + `business/intentionAudit`, form)
  },

  //合同管理

  getSigndelete(id, unlock) {
    return axios.delete(api + `business/sign/delete/${id}/${unlock}`)
  },
  getSign(params) {
    return axios.post(api + 'business/sign/search', {
      params,
      sorter: {},
    })
  },
  getSignId(id) {
    return axios.get(api + `business/sign/${id}`)
  },
  getSignhistory(id) {
    return axios.get(api + `business/sign/audits/${id}`)
  },
  getSignlist() {
    //获取列表
    return axios.get(api + 'business/sign/list')
  },
  putSign(form) {
    return axios.put(api + `business/sign`, form)
  },
  billSign(form) {
    return axios.post(api + 'business/sign/bill', form)
  },
  AddSign(form) {
    return axios.post(api + 'business/sign', form)
  },
  deleteSign(key) {
    return axios.delete(api + 'business/sign', { data: key })
  },
  getSignstate(id, state) {
    return axios.patch(api + 'business/sign/state', { id, state })
  },
  getSignchange1(form) {
    return axios.patch(api + 'business/sign/change1', form)
  },
  getSignchange2(form) {
    return axios.patch(api + 'business/sign/change2', form)
  },
  getSignchangeInfo(id) {
    return axios.get(api + `business/sign/changeInfo/${id}`)
  },
  getSignchangeterms(id) {
    return axios.get(api + `business/sign/change/${id}`)
  },
  getSignreset(id) {
    return axios.get(api + `business/sign/reset/${id}`)
  },
  //终止-清单
  getSignpreviewId(id) {
    return axios.post(api + `business/sign/finish/preview/${id}`)
  },
  getSignsubmitId(id) {
    return axios.post(api + `business/sign/finish/submit/${id}`)
  },

  //提交合同
  getsignfile(id, files) {
    return axios.patch(api + 'business/sign/resource', { id, files })
  },
  getsigngreement(id, agreement) {
    return axios.patch(api + 'business/sign/agreement', {
      id,
      agreement,
    })
  },
  Signbelongto(id, belongTo) {
    //更改意向所属
    return axios.patch(api + 'business/sign/belong', { id, belongTo })
  },
  getsignterm(id) {
    return axios.get(api + `business/sign/temp/${id}`)
  },
  deletesignterm(tempId) {
    return axios.delete(api + `business/sign/temp/${tempId}`)
  },
  putsignterm(form) {
    return axios.post(api + 'business/sign/temp', form)
  },
  postsignterm(params) {
    return axios.post(api + 'business/sign/temp/search', {
      params,
      sorter: {},
    })
  },
  //变更 退订 作废
  getsigndisuse(form) {
    return axios.patch(api + 'business/sign/disuse', form)
  },
  //提交
  signsubmit(form) {
    return axios.patch(api + 'business/sign/submit', form)
  },
  signcancel(form) {
    return axios.patch(api + 'business/sign/cancel', form)
  },
  getSignstateback(form) {
    return axios.patch(api + 'business/sign/back', form)
  },
  //   signreset(form) {
  //     return axios.patch(api + "business/sign/reset", form);
  //   },
  //签约审核
  getsignAuditsearch(current, pageSize) {
    return axios.post(api + 'business/signAudit/search', {
      params: { current, pageSize },
      sorter: {},
    })
  },
  getsignAuditrecord(current, pageSize) {
    return axios.post(api + 'business/signAudit/record', {
      params: { current, pageSize },
      sorter: {},
    })
  },
  postsignAudit(form) {
    return axios.post(api + `business/signAudit`, form)
  },
  //收款管理
  getPaymentlist(page) {
    return axios.post(api + 'business/paymentList/search', page)
  },
  getPaymentlistId(id) {
    return axios.get(api + `business/paymentList/${id}`)
  },
  putPaymentlist(form) {
    return axios.put(api + `business/paymentList`, form)
  },
  AddPaymentlist(form) {
    return axios.post(api + 'business/paymentList', form)
  },
  deletePaymentlist(key) {
    return axios.delete(api + 'business/paymentList', { data: key })
  },
  //财务
  getcurrent() {
    return axios.get(api + `finance/zlreport/current`)
  },
  getcurrenthistory() {
    return axios.get(api + `finance/zlreport/history`)
  },
  getzlreport(params) {
    return axios.post(api + 'finance/zlreport/search', {
      params,
      sorter: { 'cb.bill_date': 'desc' },
      filter: {},
    })
  },
  getfinbillhistory(id) {
    return axios.get(api + `finance/bill/audits/${id}`)
  },

  //账单管理 /finance/bill/searchNum
  getPaymentInfo(params) {
    return axios.post(api + 'finance/bill/search', {
      params,
      sorter: { 'cb.bill_date': 'desc' },
      filter: {},
    })
  },
  getPaymentInfoId(id) {
    return axios.get(api + `finance/bill/detail/${id}`)
  },
  getPaymentInforecord(id) {
    return axios.get(api + `finance/bill/record/${id}`)
  },
  putPaymentInfo(form) {
    return axios.post(api + `finance/bill/confirm`, form)
  },
  getPaymentInfolist() {
    return axios.get(api + `finance/bill/list`)
  },
  getbillsearchNum() {
    return axios.get(api + 'finance/bill/searchNum')
  },
  getibillsearchNum() {
    return axios.get(api + 'finance/ibill/searchNum')
  },
  //意向金
  getibillInfo(params) {
    return axios.post(api + 'finance/ibill/search', {
      params,
      sorter: { 'cb.bill_date': 'desc' },
      filter: {},
    })
  },
  getibillInfoId(id) {
    return axios.get(api + `finance/bill/ibill/${id}`)
  },
  getibillconfirm2(form) {
    return axios.post(api + 'finance/bill/confirm2', form)
  },
  getibillrefund(form) {
    return axios.post(api + 'finance/ibill/refund', form)
  },
  //核销审核
  getbillId(id) {
    return axios.get(api + `finance/bill/${id}`)
  },
  getflowAuditsearch(current, pageSize) {
    return axios.post(api + 'finance/flowAudit/search', {
      params: { current, pageSize },
      sorter: { 'cf.create_time': 'desc' },
      filter: {},
    })
  },
  getflowAuditrecord(current, pageSize) {
    return axios.post(api + 'finance/flowAudit/record', {
      params: { current, pageSize },
      sorter: { 'cf.create_time': 'desc' },
      filter: {},
    })
  },
  postfinanceAudit(form) {
    return axios.post(api + `finance/flowAudit`, form)
  },
  //收支
  getflowAudit(current, pageSize) {
    return axios.post(api + 'finance/flow/search', {
      params: { current, pageSize },
      sorter: { 'cf.create_time': 'desc' },
      filter: {},
    })
  },
  getfinanceflowId(id) {
    return axios.get(api + `finance/flow/${id}`)
  },
  getPaymentId(id) {
    //根据id获取意向收款详情
    return axios.get(api + `finance/paymentList/${id}`)
  },
  getResetPayment(id) {
    //重置收款状态
    return axios.get(api + `finance/paymentList/reset/${id}`)
  },
  getPaymentAuditId(id) {
    //获取审核记录
    return axios.get(api + `finance/paymentList/audits/${id}`)
  },
  Paymentstate(id, state) {
    return axios.patch(api + 'finance/paymentList/state', { id, state })
  },
  //结算管理
  getfinanceclear(params) {
    return axios.post(api + 'finance/clear/search', {
      params,
      sorter: { create_time: 'desc' },
      filter: {},
    })
  },
  putfinanceclear(form) {
    return axios.put(api + `finance/clear`, form)
  },
  //运营
  getoperatebill(params) {
    return axios.post(api + 'operate/bill/search', {
      params,
      sorter: { 'cb.bill_date': 'desc' },
      filter: {},
    })
  },
  getoperateokId(id) {
    return axios.post(api + `operate/bill/ok/${id}`)
  },
  postoperateokId(id, form) {
    return axios.post(api + `operate/bill/addSub/${id}`, form)
  },
  getoperateokback(id) {
    return axios.post(api + `operate/bill/cancel/${id}`)
  },
  //租户
  getoperatetenant(params) {
    return axios.post(api + 'operate/tenant/search', {
      params,
      sorter: { 'cb.name': 'asc', 'cf.name': 'asc', 'cs.name': 'asc' },
      filter: {},
    })
  },
  getoperatetenantid(id) {
    return axios.get(api + `operate/tenant/info/${id}`)
  },
  getoperatepaperTypelist() {
    return axios.get(api + `data/paperType/list`)
  },
  getoperatetenantstate(id, type, state) {
    return axios.get(api + `operate/tenant/state/${id}/${type}/${state}`)
  },
  getoperatetenantfiles(id, type, form) {
    return axios.post(api + `operate/tenant/files/${id}/${type}`, form)
  },
  getoperatedeletefiles(id, type, form) {
    return axios.post(api + `operate/tenant/dFiles/${id}/${type}`, form)
  },
  //巡检
  getpatrol(params) {
    return axios.post(api + 'patrol/site/search', {
      params,
      filter: {},
      sorter: {},
    })
  },
  Addpatrolsite(form) {
    return axios.post(api + 'patrol/site', form)
  },
  putpatrolsite(form) {
    return axios.put(api + 'patrol/site', form)
  },
  deletepatrolsite(id) {
    return axios.delete(api + 'patrol/site', { data: id })
  },
  getpatrolsiteId(id) {
    return axios.get(api + `patrol/site/${id}`)
  },
  getpatrolsitecode(id) {
    return axios.post(api + `patrol/site/list/${id}`)
  },
  getpatrolsitestate(id, state) {
    return axios.patch(api + `patrol/site/state`, { id, state })
  },
  getpatrolqrAll() {
    return axios.post(api + `patrol/site/qrList`)
  },
  //   getpatrolsitelistc() {
  //     return axios.get(api + `patrol/site/list`);
  //   },
  getpatrolsitelist(buildingId, floorId) {
    let pas = ''
    if (buildingId != undefined) {
      pas += `?buildingId=${buildingId}`
    }
    if (floorId != undefined) {
      if (buildingId != undefined) {
        pas += `&floorId=${floorId}`
      } else {
        pas += `?floorId=${floorId}`
      }
    }
    return axios.get(api + 'patrol/site/list' + pas)
  },
  //路线
  getpatrolline(params) {
    return axios.post(api + 'patrol/line/search', {
      params,
      filter: {},
      sorter: {},
    })
  },
  Addpatrolline(form) {
    return axios.post(api + 'patrol/line', form)
  },
  putpatrolline(form) {
    return axios.put(api + 'patrol/line', form)
  },
  deletepatrolline(id) {
    return axios.delete(api + 'patrol/line', { data: id })
  },
  getpatrollineId(id) {
    return axios.get(api + `patrol/line/${id}`)
  },
  getpatrollinestate(id, state) {
    return axios.patch(api + `patrol/line/state`, { id, state })
  },
  getpatrollinelist() {
    return axios.get(api + `patrol/line/list`)
  },
  //计划
  getpatrolplan(params) {
    return axios.post(api + 'patrol/plan/search', {
      params,
      filter: {},
      sorter: {},
    })
  },
  Addpatrolplan(form) {
    return axios.post(api + 'patrol/plan', form)
  },
  putpatrolplan(form) {
    return axios.put(api + 'patrol/plan', form)
  },
  deletepatrolplan(id) {
    return axios.delete(api + 'patrol/plan', { data: id })
  },
  getpatrolplanId(id) {
    return axios.get(api + `patrol/plan/${id}`)
  },
  getpatrolplanstate(id, state) {
    return axios.patch(api + `patrol/plan/state`, { id, state })
  },
  getschedule(year, month) {
    return axios.get(api + `patrol/plan/schedule/${year}/${month}`)
  },
  //任务
  getpatroltask(params) {
    return axios.post(api + 'patrol/task/search', {
      params,
      filter: {},
      sorter: {},
    })
  },
  deletepatroltask(key) {
    return axios.delete(api + 'patrol/task', { data: key })
  },
  getpatroltaskId(id) {
    return axios.get(api + `patrol/task/${id}`)
  },
  //问题
  getpatrolproblem(params) {
    return axios.post(api + 'patrol/problem/search', {
      params,
      filter: {},
      sorter: {},
    })
  },
  getpatrolproblemId(id) {
    return axios.get(api + `patrol/problem/${id}`)
  },
  getpatrolproblemstate(id, state) {
    return axios.patch(api + `patrol/problem/state`, { id, state })
  },
  //意向金
  getoperateibillInfo(params) {
    return axios.post(api + 'operate/ibill/search', {
      params,
      sorter: { 'cb.bill_date': 'desc' },
      filter: {},
    })
  },
  getconfigpositionlist() {
    return axios.get(api + `config/position/list`)
  },

  //审批中心
  getauditCenter(params) {
    return axios.post(api + 'audit/auditCenter/search', {
      params,
      sorter: { id: 'desc' },
      filter: {},
    })
  },
  getauditCenteraud(params) {
    return axios.post(api + 'audit/auditCenter/searchAudit', {
      params,
      sorter: { id: 'desc' },
      filter: {},
    })
  },
  getauditCentersub(params) {
    return axios.post(api + 'audit/auditCenter/searchSubmit', {
      params,
      sorter: { id: 'desc' },
      filter: {},
    })
  },
  getauditaudit(form) {
    return axios.post(api + 'audit/auditCenter/audit', form)
  },
  getauditaudits(form) {
    return axios.post(api + 'audit/auditCenter/audits', form)
  },
  getauditCenterrecord(id) {
    return axios.post(api + 'audit/auditCenter/record', { id })
  },
  getauditCenterlink(id) {
    return axios.post(api + 'audit/auditCenter/link', { id })
  },
  //审核进度

  getauditrecord(linkType, linkId, processId, version) {
    return axios.get(
      api +
        `audit/auditCenter/record/${linkType}/${linkId}/${processId}/${version}`
    )
  },
  getauditrecords(linkType, linkId) {
    return axios.get(api + `audit/auditCenter/record/${linkType}/${linkId}`)
  },
  getuserinfo(userId) {
    return axios.get(api + `dashboard/userinfo/${userId}`)
  },
  getmarkSysRead(id) {
    return axios.post(api + `message/markSysRead`, id)
  },
  getsearchHistory(params) {
    return axios.post(api + 'audit/auditCenter/searchHistory', {
      params,
      filter: {},
      sorter: { 'ca.id': 'desc' },
    })
  },
  getprojectInfo() {
    return axios.get(api + `business/rentMap/projectInfo`)
  },
  getbuildingStoreInfo(buildingId) {
    return axios.get(api + `business/rentMap/buildingStoreInfo/${buildingId}`)
  },
  getfloorStoreInfo(buildingId) {
    return axios.get(api + `business/rentMap/floorStoreInfo/${buildingId}`)
  },
  //小程序
  getxcxconfig() {
    return axios.get(api + `xcx/config`)
  },
  postxcxconfig(form) {
    return axios.post(api + `xcx/config`, form)
  },
  getxcxdashboard(params) {
    return axios.post(api + 'xcx/dashboard/search', {
      params,
      filter: {},
      sorter: { 'ca.id': 'desc' },
    })
  },
  addxcxconfig(form) {
    return axios.post(api + `xcx/dashboard/add`, form)
  },
  putxcxdashboard(form) {
    return axios.put(api + `xcx/dashboard/update`, form)
  },
  getxcxdashboardstate(id, isHome) {
    return axios.patch(api + `xcx/dashboard/isHome`, { id, isHome })
  },
  getxcxdashboardid(id) {
    return axios.get(api + `xcx/dashboard/get/${id}`)
  },
  deletexcxdashboard(id) {
    return axios.delete(api + `xcx/dashboard/delete/${id}`)
  },

  geteditionpackage() {
    return axios.get(api + `edition/package`)
  },
  geteditionpackageid(id) {
    return axios.get(api + `edition/package/detail/${id}`)
  },
  geteditionusage() {
    return axios.get(api + `edition/package/usage`)
  },
  geteditionpackagelist() {
    return axios.get(api + `edition/package/list`)
  },
  getniu() {
    return axios.get(api + `lc.svg`)
    // return axios.get(
    //   `https://cdn.jsdelivr.net/gh/apache/echarts-website@asf-site/examples/data/asset/geo/Beef_cuts_France.svg`
    // );
  },
  //

  getorderquery(params) {
    return axios.post(api + 'order/query/search', {
      params,
      sorter: { id: 'desc' },
      filter: {},
    })
  },
  //数据
  getloginHistory(params) {
    return axios.post(api + 'security/loginHistory/search', {
      params,
      filter: {},
      sorter: { id: 'desc' },
    })
  },
  getoperationHistory(params) {
    return axios.post(api + 'security/operationHistory/search', {
      params,
      filter: {},
      sorter: { id: 'desc' },
    })
  },
  //模块-充值缴费
  getalipayamount(amount, subject) {
    return axios.get(api + `pay/alipay?amount=${amount}&subject=${subject}`)
  },
  getwxpayamount(amount, subject) {
    return axios.get(api + `pay/wxpay?amount=${amount}&subject=${subject}`)
  },
  //套餐数量提示
  //项目
  getprojectrest() {
    return axios.get(api + `property/project/rest`)
  },
  //项目
  getconfigenterpriserest() {
    return axios.get(api + `config/enterprise/rest`)
  },
  getconfiguserrest() {
    return axios.get(api + `config/user/rest`)
  },
  //洽谈中的锁定状态
  getpropertylock(id, locked) {
    return axios.patch(api + `property/store/lock`, { id: id, locked: locked })
  },
  //wps生成模板
  generatewps() {
    return axios.get(api + `wps/api/generate`)
  },
}
